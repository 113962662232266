<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/raceManagement/team_management/team_race_management' }">团体赛赛务管理 </el-breadcrumb-item>
      <el-breadcrumb-item>参赛战队</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-tabs type="border-card">
      <teamInfoForm :raceSchedule="raceSchedule"></teamInfoForm>
    </el-tabs>
  </div>
</template>

<script>
import teamInfoForm from '../components/itso_teamInfo_form.vue'

export default {
  data () {
    return {
      raceSchedule: this.$route.query.raceSchedule
    }
  },
  components: { teamInfoForm },
  methods: {
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style>
</style>
